import Link from "@/components/link";
import React from "react";
import styles from "./page_foot.module.css";
import Tooltip from "../tooltip";

import baiduQrcode from "@/public/assets/images/baidu.png";
import kefuQrcode from "@/public/assets/images/kefu.png";
import gongzhonghaoQrcode from "@/public/assets/images/gongzhonghao.jpg";
import xiaochengxuQrcode from "@/public/assets/images/xiaochengxu.jpg";

export default function PageFoot({
  activityLink,
  newsLink,
  websiteInfo,
}: {
  activityLink: string;
  newsLink: string;
  websiteInfo: SiteInfo;
}) {
  return (
    <footer className={styles.page_foot}>
      <div className={styles.page_foot__content}>
        <ul className={styles.page_foot__link}>
          <li className={styles.page_foot__link_item}>
            <h3>发展历程</h3>
            <div className={styles.items_wrapper}>
              <Link className={styles.link} href="/about">
                关于我们
              </Link>
              <Link className={styles.link} href="/about/society">
                关于学会
              </Link>
              <Link className={styles.link} href="/about/charter">
                架构及章程
              </Link>
              <Link className={styles.link} href="/about/building">
                党建园地
              </Link>
              <Link className={styles.link} href="/about/job">
                工作机会
              </Link>
              <Link className={styles.link} href="/about/contact">
                联系我们
              </Link>
            </div>
          </li>
          <li className={styles.page_foot__link_item}>
            <h3>学术活动</h3>
            <div className={styles.items_wrapper}>
              <Link className={styles.link} href="/rare_disease">
                罕见病大会
              </Link>
              <Link className={styles.link} href={activityLink}>
                活动
              </Link>
              <Link className={styles.link} href={newsLink}>
                动态
              </Link>
            </div>
          </li>
          <li className={styles.page_foot__link_item}>
            <h3>医疗临床及数据服务</h3>
            <div className={styles.items_wrapper}>
              <Link
                className={styles.link}
                rel="nofollow"
                href="https://upwards.chard.org.cn/"
                target={"_blank"}
              >
                国家财政专项
              </Link>
              <Link
                className={styles.link}
                rel="nofollow"
                href="https://app.chard.org.cn/"
                target={"_blank"}
              >
                罕见病综合云服务平台
              </Link>
              <Link
                className={styles.link}
                rel="nofollow"
                href="https://www.nrdrs.org.cn/"
                target={"_blank"}
              >
                中国国家罕见病注册系统
              </Link>
              <Link
                className={styles.link}
                rel="nofollow"
                href="https://zhibao.nrdrs.org.cn/"
                target={"_blank"}
              >
                中国罕见病诊疗服务信息系统
              </Link>
              <Link
                className={styles.link}
                rel="nofollow"
                href="https://app.chard.org.cn/"
                target={"_blank"}
              >
                软骨发育不全（ACH）全国多中心登记研究
              </Link>
            </div>
          </li>

          <li className={styles.page_foot__link_item}>
            <h3>帮助与支持</h3>
            <div className={styles.items_wrapper}>
              <Tooltip
                miniProgramCode={kefuQrcode}
                miniProgramTitle="在线客服"
                placement="right"
                miniProgramContent="扫码联系在线客服"
              >
                <Link href="#" className={styles.link}>
                  在线客服
                </Link>
              </Tooltip>
            </div>
          </li>

          <li className={styles.page_foot__link_item}>
            <h3>移动平台</h3>
            <div className={styles.items_wrapper}>
              <Tooltip
                miniProgramCode={gongzhonghaoQrcode}
                miniProgramTitle="微信公众号二维码"
                miniProgramContent="扫码关注微信公众号"
                placement="right"
              >
                <div className={styles.qrcode_item}>
                  <p>微信公众号</p>
                </div>
              </Tooltip>
              <Tooltip
                miniProgramCode={xiaochengxuQrcode}
                miniProgramTitle="微信小程序小程序码"
                miniProgramContent="扫码进入微信小程序"
                placement="right"
              >
                <div className={styles.qrcode_item}>
                  <p>微信小程序</p>
                </div>
              </Tooltip>
              <Tooltip
                miniProgramCode={baiduQrcode}
                miniProgramTitle="百度小程序二维码"
                miniProgramContent="扫码进入百度小程序"
                placement="right"
              >
                <div className={styles.qrcode_item}>
                  <p>百度小程序</p>
                </div>
              </Tooltip>
            </div>
          </li>
        </ul>
        <div className={styles.page_foot__info}>
          {websiteInfo.copyright}{" "}
          <Link className={styles.linkICP} href={websiteInfo.icpUrl} target="_blank">
            {websiteInfo.icpNumber}
          </Link>
        </div>
      </div>
    </footer>
  );
}
